import * as React from "react";
import { Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import WorldMapPage from "./components/WorldMapPage";
import LandingPage from "./components/LandingPage";
import PageTitle from "./components/PageTitle";
import getLPTheme from "./getLPTheme";
import ProfilePage from "./components/Profile/ProfilePage";
import LeaderboardPage from "./components/Leaderboard/LeaderboardPage";
import AppAppBar from "./components/AppAppBar";

const zombieTrain = "ZombieTrain";
export default function App() {
  const LPtheme = createTheme(getLPTheme("dark"));

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <Box>
        <AppAppBar />
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PageTitle title={zombieTrain + " - Home"} />
                  <LandingPage />
                </>
              }
            />
            <Route
              path="/world-map"
              element={
                <>
                  <PageTitle title={zombieTrain + " - World Map"} />
                  <WorldMapPage />
                </>
              }
            />
            <Route
              path="/leaderboard"
              element={
                <>
                  <PageTitle title={zombieTrain + " - Leaderboard"} />
                  <LeaderboardPage />
                </>
              }
            />
            <Route
              path="/profile/:userId"
              element={
                <>
                  <PageTitle title={zombieTrain + " - Profile"} />
                  <ProfilePage />
                </>
              }
            />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}
