import * as React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Hero from "./Hero";
import Footer from "./Footer";
import Leaderboard from "./Leaderboard/Leaderboard";

export default function LandingPage() {
  return (
    <>
      <Hero />
      <Divider />
      <a href="/leaderboard">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Leaderboard />
        </Box>
      </a>

      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <Footer />
      </Box>
    </>
  );
}
