import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Skeleton, TablePagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Leaderboard.module.scss";

interface Row {
  position: number;
  user_id: number;
  user_name: string;
  total_score: number;
}

interface LeaderBoardData {
  total: number;
  data: Row[];
}

function Leaderboard() {
  const [rows, setRows] = useState<Row[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const fetchData = () => {
    setLoading(true);
    const start = page * rowsPerPage;
    fetch(
      "/api/leaderboard?" +
        new URLSearchParams({
          offset: start.toString(),
          limit: rowsPerPage.toString(),
        })
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then(({ data, total }: LeaderBoardData) => {
        setTotal(total);
        setRows(data);
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (_: React.MouseEvent | null, page: number) =>
    setPage(page);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (userId: number) => () => {
    navigate("/profile/" + userId);
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer
      id="leaderboard"
      component={Paper}
      className={styles.tableContainer}
    >
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell align="left">Nickname</TableCell>
            <TableCell align="right">Total kills</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading &&
            Array(rowsPerPage)
              .fill(1)
              .map((_, i) => (
                <TableRow key={i} style={{ cursor: "pointer" }}>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rectangular" height={20} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rectangular" height={20} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Skeleton variant="rectangular" height={20} />
                  </TableCell>
                </TableRow>
              ))}
          {!loading &&
            rows.map((row) => (
              <TableRow
                key={row.user_id}
                onClick={handleRowClick(row.user_id)}
                style={{ cursor: "pointer" }}
              >
                <TableCell component="th" scope="row">
                  {row.position}
                </TableCell>
                <TableCell align="left">{row.user_name}</TableCell>
                <TableCell align="right">{row.total_score}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={total}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default Leaderboard;
