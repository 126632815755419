import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Leaderboard from "./Leaderboard";
import Footer from "../Footer";
import PreviousDayHero from "../YesterdaysHero";
import styles from "../../Main.module.scss";

export default function LeaderboardPage() {
  return (
    <Box className={styles.pageBox}>
      <Container className={styles.pageContainer}>
        <PreviousDayHero />
        <Leaderboard />
      </Container>
      <Footer />
    </Box>
  );
}
