import { Chip, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import styles from "./Profile.module.scss";
import Avatar from "./Avatar";

interface User {
  id: number;
  username: string;
  current_region_id: number;
  current_region_name: string;
  current_region_score_value: number;
  is_banned: boolean;
  is_cheater: boolean;
  is_suspicious: boolean;
  date_joined: string;
}

const formatDate = (date: Date) => {
  const yyyy = date.getFullYear();
  let mm: string | number = date.getMonth() + 1;
  let dd: string | number = date.getDate();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
};

export default function Profile({ userId }: { userId?: string }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const fetchData = () => {
    if (!userId) return;
    setLoading(true);
    fetch(`/api/users/${userId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((user: User) => {
        setUser({
          ...user,
          date_joined: formatDate(new Date(user.date_joined)),
        });
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!user) return <div>There is no such user</div>;

  return (
    <div className={styles.wrapper}>
      <Paper className={styles.mainInfo}>
        <div className={styles.mainInfoAvatar}>
          <Avatar />
        </div>
        <div className={styles.userName}>
          <div>{user.username}</div>
          <div>
            {user.is_banned && <Chip label="Banned" />}
            {user.is_cheater && <Chip label="Cheater" />}
          </div>
        </div>
        <div>
          <div>RANK</div>
          <div className={styles.rank}>{user.id}</div>
        </div>
      </Paper>
      <Paper className={styles.stats}>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>User id</div>
          <div className={styles.statsBlock}>{user.id}</div>
        </div>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>Register date</div>
          <div className={styles.statsBlock}>{user.date_joined}</div>
        </div>
        <div className={styles.statsLine}>
          <div className={styles.statsBlock}>User region</div>
          <div className={styles.statsBlock}>{user.current_region_name}</div>
        </div>
      </Paper>
    </div>
  );
}
