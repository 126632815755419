import styles from "./Profile.module.scss";
import avatarWrapper from "../../images/avatarwrapper.png";
import avatar from "../../images/avatar.png";

export default function Avatar() {
  return (
    <div className={styles.avatarContainer}>
      <img src={avatarWrapper} className={styles.avatarWrapper} />
      <img src={avatar} className={styles.avatar} />
    </div>
  );
}
