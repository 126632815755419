import { Box, Container } from "@mui/material";
import Profile from "./Profile";
import { useParams } from "react-router-dom";
import styles from "../../Main.module.scss";

export default function ProfilePage() {
  let { userId } = useParams();

  return (
    <Box className={styles.pageBox}>
      <Container className={styles.pageContainer}>
        <Profile userId={userId} />
      </Container>
    </Box>
  );
}
